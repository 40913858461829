<template>
  <div class="mb-20">
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold">All affiliate users</h2>
        <router-link
          :to="{ name: 'users-new-affiliate' }"
          class="text-sm text-gray-700 md:ml-3"
          >+ Add new affiliate user</router-link
        >
      </div>
      <search-form
        v-model="searchQuery"
        placeholder="Search for user's name, status, etc."
        @submit="(event) => $refs.table.loadAjaxData()"
      />
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex">
        <div
          class="duration-300"
          :class="{ 'w-full': !isOpen, 'w-1/2': isOpen }"
        >
          <datatable
            :url="url"
            :ajax="true"
            :ajaxPagination="true"
            :fillable="true"
            :index="true"
            :reverse-index="true"
            :columns="columns"
            :exportable="true"
            :pageDetails="true"
            :onClick="click"
            :query="searchQuery"
            :className="tableRowClassName"
            :loading="users.loading"
            :actions="actions"
            ref="table"
          />
        </div>
        <div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
          <div class="h-full overflow-y-auto">
            <div class="animated animation-delay-300 fadeIn">
              <div class="mb-5">
                <div class="flex flex-row items-center justify-between mb-1">
                  <div class="flex flex-row items-center">
                    <h2 class="text-lg font-bold mr-2">
                      {{ selectedUser.first_name | sentenceCase }}
                      {{ selectedUser.last_name | sentenceCase }}
                    </h2>
                  </div>
                  <div class="dropdown ml-auto">
                    <button
                      type="button"
                      class="flex flex-col focus:outline-none"
                      dropdown
                    >
                      <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                      <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                      <div class="h-1 w-1 bg-gray-400 rounded-full"></div>
                    </button>
                    <ul class="dropdown-menu">
                      <a
                        href="#!"
                        class="dropdown-menu-item text-red-500"
                        @click.prevent="confirmDelete"
                        >Delete</a
                      >
                    </ul>
                  </div>
                </div>
                <div class="font-hairline text-xs text-gray-500">
                  {{ position | sentenceCase }}
                </div>
                <router-link
                  :to="{
                    name: 'affiliate-view',
                    params: { userId: selectedUser.id },
                  }"
                  class="inline-flex items-center text-sm text-blue-500"
                >
                  <span>View Profile</span>
                </router-link>
              </div>

              <transition name="fade" mode="out-in">
                <div class="h-100px" v-if="userApprovalLoading">
                  <sm-loader />
                </div>

                <div v-else>
                  <!-- Profile Not Completed -->
                  <div class="mb-10" v-if="!userProfileCompleted">
                    <div class="alert alert-blue-soft mb-5">
                      <span class="alert-icon">!</span>
                      <span
                        >{{ userFirstName }} hasn't completed their
                        profile.</span
                      >
                    </div>
                  </div>
                  <!-- Profile Not Completed -->

                  <!-- Profile Completed -->
                  <template v-else>
                    <div class="text-xs">
                      <template v-if="userApprovalCompleted">
                        <div class="alert alert-green-soft mb-5">
                          <span class="alert-icon">!</span>
                          <div class="text-xs font-normal">
                            <template
                              v-if="
                                userApproval &&
                                  userApproval.action == 'activate'
                              "
                            >
                              Profile activated successfully.
                            </template>
                            <template v-else>
                              Profile deactivated successfully.
                            </template>
                          </div>
                        </div>
                      </template>

                      <template v-else-if="userApprovalPending">
                        <div class="alert alert-blue-soft mb-5">
                          <span class="alert-icon">!</span>
                          <span
                            >{{ userFirstName }}'s profile needs
                            activation.</span
                          >
                        </div>
                      </template>
                    </div>
                  </template>
                  <!-- Profile Completed -->
                </div>
              </transition>

              <div
                class="grid grid-cols-1 xl:grid-cols-2 border-blue-200 border mb-8"
              >
                <column colName="Phone Number" :colValue="selectedUser.phone" icon="call-outline" />
                <column colName="Email" :colValue="selectedUser.email" icon="mail-outline" />
                <column colName="Account Number" :colValue="selectedUser.profile.account_no" icon="mail-outline" />
                <column colName="Bank Name" :colValue="selectedUser.profile.bank_name" icon="business-outline" />
                <column colName="Total Leads" :colValue="this.selectedUserSummary.total_leads" icon="people-outline" />
                <column colName="Total Converted" :colValue="this.selectedUserSummary.total_approved" icon="arrow-redo-circle-outline" />
                <column colName="Total Disbured" :colValue="totalDisbursed | currency" :isNaira="true"/>
                <column colName="Ad Url" :colValue="this.selectedUser.code.social_dynamic_link" icon="person-add-outline"/>
                <column colName="Embedded Url" :colValue="this.selectedUser.code.embed_dynamic_link" icon="link-outline"/>
                <column colName="Code" :colValue="this.selectedUser.code.code" icon="code-slash-outline"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      className="w-full md:w-5/10 xl:w-4/10 text-center pt-24"
      ref="attachAffiliateModal"
      @close="emptyAllArrays"
    >
      <h5>
        Attach Affiliate to
        <span v-if="selectedAffiliate"
          >{{ selectedAffiliate.first_name }}
          {{ selectedAffiliate.last_name }}</span
        >
      </h5>
      <template v-if="attachAffiliateError">
        <div class="alert alert-red-soft mt-4 mb-4">
          <span class="alert-icon">!</span>
          <span class="text-xs font-normal">{{ attachAffiliateError }}</span>
        </div>
      </template>

      <div class="form-group mb-4 mt-4">
        <input
          type="search"
          name="search"
          class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
          placeholder="Search Affiliates"
          @keyup.enter="searchEnterKey"
          v-model="attachQuery"
        />
      </div>
      <div class="flex">
        <template v-for="affiliate in selectedUserDetails.slice(0, 4)">
          <div
            :key="affiliate.id"
            class="rounded-full h-12 w-12 flex items-center justify-center mr-1 my-4 relative"
            :style="{ backgroundColor: getRandomColor() }"
          >
            <span
              @mouseover="showToolTip = true"
              @mouseleave="showToolTip = false"
              class="text-left"
              >{{ affiliate.firstName[0] }}{{ affiliate.lastName[0] }}</span
            >
            <!-- v-if="copied" -->
            <div
              v-if="showToolTip"
              class="text-xs bg-white text-center rounded absolute copy-clipboard"
            >
              {{ affiliate.firstName }} {{ affiliate.lastName }}
            </div>
          </div>
        </template>
        <span
          v-if="selectedUserDetails.length > 4"
          class="self-center"
          @click.prevent="displayUsers"
        >
          + {{ selectedUserDetails.length - 4 }}
          <span>
            <ion-icon name="caret-down-outline"></ion-icon>
          </span>
        </span>
      </div>
      <div
        v-if="showExtraUsers"
        class="bg-gray-400 absolute max-h-10 w-7/10 py-4 px-4 rounded-md overflow-y-scroll"
      >
        <template v-for="affiliate in selectedUserDetails.slice(4)">
          <div :key="affiliate.id" class="text-left">
            {{ affiliate.firstName }} {{ affiliate.lastName }}
          </div>
        </template>
      </div>
      <div class="max-h-30 overflow-y-scroll">
        <div class="h-100px" v-if="loading">
          <sm-loader />
        </div>
        <template v-else v-for="affiliate in affiliateUsersModal">
          <!-- <div class="custom-control custom-checkbox" @click="selectItem(item)"> -->
          <div :key="affiliate.id" class="flex">
            <input
              type="checkbox"
              class="custom-control-input mr-4 mt-1"
              @change="selectAffiliate"
              :checked="checkSelected(affiliate.id)"
              :id="affiliate.id"
              :data-first="affiliate.first_name"
              :data-last="affiliate.last_name"
              :data-id="affiliate.id"
            />
            <span class="text-left"
              >{{ affiliate.first_name }} {{ affiliate.last_name }} -
              {{ affiliate.phone }}</span
            >
          </div>
        </template>
      </div>

      <button
        class="w-full mt-10 btn btn-blue rounded bg-blue-500 py-1 md:py-4 xl:py-4 px-8 md:px-14 xl:px-14 font-normal text-white lg:ml-1"
        @click.prevent="submitSelectedAffiliate"
        :disabled="loading"
      >
        Submit
      </button>
    </modal>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="responseModal"
    >
      <img
        :src="
          responseStatus
            ? assets.icons.lg.checkmark
            : assets.icons.lg.exclamation
        "
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        <span v-if="responseStatus">Success!</span>
        <span v-if="!responseStatus">Failed!</span>
      </div>
      <div class="text-xs mb-10">
        {{ responseMessage }}
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    Column: () => import('../components/Column')
  },
  data() {
    return {
      referralUrl: "http://credpal.com/",
      url: `${this.$baseurl}/admin/affiliates`,
      responseStatus: false,
      responseMessage: "",
      showToolTip: false,
      showExtraUsers: false,
      actions: [
        {
          text: "Make Leader",
          class:
            "border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200",
          action: this.upgradeAffiliate,
          disabled: (affiliate) => !!affiliate.originator,
        },
        {
          text: "Attach Affiliate",
          class:
            "border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200",
          action: this.openAttachAffiliateModal,
          // disabled: (account) =>
          //   account.status === "paid" || account.status === "transferred",
        },
      ],
      columns: [
        {
          name: "full_name",
          th: "Name",
          render: (user) => {
            return `${user.first_name} ${user.last_name}`;
          },
        },
        {
          name: "email",
          th: "Email Address",
        },
        {
          name: "phone",
          th: "Phone No",
        },
        {
          name: "created_at",
          th: "Reg Date",
          render: (user) => {
            return new Date(user.created_date).format("dd, M Y");
          },
        },
        {
          name: "originator",
          th: "Source",
          render: (user) => {
            if (!user.originator) {
              return "CredPal";
            }
            return `${user.originator?.originator?.first_name} ${user.originator?.originator?.last_name}`;
          },
        },
        {
          name: "product",
          th: " Product",
          render: (user) => {
            return `${user?.code?.affiliate_reward_setting?.name || "----"}`;
          },
        },
        {
          name: "Referred customers",
          th: "Referred customers",
          render: (user) => {
            if (!user?.referred_customers) {
              return 0;
            }
            return user?.referred_customers.length;
          },
        },
        {
          name: "Total Disbursed",
          th: "Total disbursed",
          render: (user) => {
            if (!user?.rewards) {
              return this.$options.filters.currency(0);
            }
            const disbursed = user.rewards
              .filter((reward) => reward.status === "disbursed")
              .map((reward) => reward.commission);
            if (!disbursed.length) {
              return this.$options.filters.currency(0);
            }
            const totalDisbursed = disbursed.reduce(
              (accumulator, current) => accumulator + current
            );
            const total = this.$options.filters.currency(totalDisbursed);
            return `₦ ${total}`;
          },
        },
        {
          name: "created_at",
          th: "Registered Date",
          render: (user) => {
            if (!user.created_date) {
              return "---";
            }
            return this.$moment(user.created_date).format("MMM Do, YYYY h:mm:ss a");
          },
        },
      ],
      deleteForm: this.$options.basicForm([]),
      selectedUser: null,
      users: this.$options.resource([]),
      approvalForm: this.$options.basicForm([
        "credit_limit",
        "loan_limit",
        "interest_rate",
        "billing_date",
        "payment_date",
      ]),
      loading: false,
      profileApprovals: [],
      searchQuery: "",
      selectedUserSummary: null,
      affiliateUsers: null,
      attachQuery: "",
      // selectedAffiliates: new Set()
      selectedAffiliates: [],
      selectedUserDetails: [],
      colorCache: {},
      selectedAffiliate: null,
      attachAffiliateError: "",
      selectedTooltip: null,
      affiliateUsersModal: null,
    };
  },
  computed: {
    ...mapState(["banks"]),
    totalConversions() {
      return this.$options.filters.currency(
        this.selectedUserSummary?.total_earned_rewards || 0
      );
    },
    totalCurrentRequest() {
      return this.$options.filters.currency(
        this.selectedUserSummary?.total_current_requested_rewards || 0
      );
    },
    totalDisbursed() {
      return this.$options.filters.currency(
        this.selectedUserSummary?.total_disbursed_rewards || 0
      );
    },
    userFirstName() {
      // return this.selectedUser?.name.split(' ')[0];
      return this.selectedUser?.firstname;
    },
    userProfileActivated() {
      return (
        (!!this.userApprovalCompleted &&
          this.userApproval.action === "activate") ||
        this.selectedUser?.profile?.status === "activated"
      );
    },
    userProfileCompleted() {
      return this.selectedUser?.profile;
    },
    userProfileDeactivated() {
      return (
        (!!this.userApprovalCompleted &&
          this.userApproval.action === "deactivate") ||
        this.selectedUser?.profile?.status === "deactivated"
      );
    },
    userApprovalPending() {
      return this.selectedUser?.profile?.status === "pending";
    },
    userApproval() {
      return this.profileApprovals.find(
        (approval) => approval.id === this.selectedUser.id
      );
    },
    userApprovalCompleted() {
      return this.userApproval?.response?.data?.status;
    },
    userApprovalError() {
      const error = this.userApproval?.error;
      if (!error) {
        return null;
      }

      if (error?.toString().match(/Error: Network Error/i)) {
        return "Please check your internet connection";
      }

      if (error?.response?.data?.message) {
        return error?.response?.data?.message;
      }

      return "Please try again later.";
    },
    userApprovalLoading() {
      return this.userApproval?.loading === true;
    },
    isOpen() {
      return !!this.selectedUser;
    },
    position() {
      return this.selectedUser?.profile?.position;
    },
    totalLoanAmount() {
      return (
        this.transactions &&
        this.transactions.reduce(
          (last, transaction) => last + Number(transaction.loan_amount),
          0
        )
      );
    },
    transactions() {
      return this.selectedUser?.loan;
    },
  },
  beforeMount() {
    this.getAffiliateUsers();
  },
  methods: {
    click(selectedUser) {
      this.selectedUser =
        this.selectedUser === selectedUser ? null : selectedUser;
      this.$refs.table.renderData();
      if (this.selectedUser) {
        this.getUserSummary();
      }
    },
    tableRowClassName(row) {
      return row.id === this.selectedUser?.id ? "selected" : "";
    },
    openAttachAffiliateModal(affiliate) {
      this.selectedAffiliate = affiliate;
      this.affiliates();
      this.$refs.attachAffiliateModal.open();
    },
    selectAffiliate(element) {
      const phoneNumber = element.target.id;
      const check = this.selectedAffiliates.findIndex(
        (affiliate) => affiliate == phoneNumber
      );
      if (check > -1) {
        this.selectedAffiliates.splice(check, 1);
        this.selectedUserDetails.splice(check, 1);
        return;
      }
      const user = {
        firstName: element.target.dataset.first,
        lastName: element.target.dataset.last,
        id: element.target.dataset.id,
      };
      const users = [...this.selectedUserDetails, user];
      const result = [...this.selectedAffiliates, phoneNumber];
      this.selectedAffiliates = result;
      this.selectedUserDetails = users;
    },
    checkSelected(affiliateId) {
      const check = this.selectedAffiliates.find(
        (affiliate) => affiliate == affiliateId
      );
      if (check) {
        return true;
      }
      return false;
    },
    displayUsers() {
      return (this.showExtraUsers = !this.showExtraUsers);
    },
    emptyAllArrays() {
      this.selectedAffiliates = [];
      this.selectedUserDetails = [];
      this.showExtraUsers = false;
      this.attachAffiliateError = "";
    },
    handleShowToolTip(id) {
      const check2 = this.selectedUserDetails.find(
        (affiliate) => affiliate.id == id
      );
      const check = this.selectedAffiliates.findIndex(
        (affiliate) => affiliate == id
      );
      this.selectedTooltip = check2;
      const result = check < 0;
      this.showToolTip = result;
      return check < 0;
    },
    getRandomColor() {
      const colors = [
        "rgba(21,174,103,.5)",
        "rgba(245,163,59,.5)",
        "rgba(255,230,135,.5)",
        "rgba(194,217,78,.5)",
        "rgba(195,123,177,.5)",
        "rgba(125,205,244,.5)",
      ];
      return colors[~~(Math.random() * colors.length)];
    },
    async getAffiliateUsers() {
      this.users.loading = true;

      await this.sendRequest("admin.affiliate.all", {
        success: (response) => {
          // this.users.data = response.data.data.data;
          this.affiliateUsers = response.data.datatable.data;
          // this.affiliateUsers = 1
        },
        error: (error) => {
          this.users.error = error;
        },
      });

      this.users.loading = false;
    },
    async getUserSummary() {
      this.users.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/affiliates/${this.selectedUser.id}/rewards/summary`,
        headers: this.headers,
        success: (response) => {
          this.selectedUserSummary = response.data.data;
        },
        error: (error) => {
          console.log("err", error);
        },
      });

      this.users.loading = false;
    },
    async upgradeAffiliate(affiliate) {
      // this.users.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/affiliates/${affiliate.id}/originator/upgrade`,
        headers: this.headers,
        success: (response) => {
          this.getAffiliateUsers();
          this.responseStatus = true;
          this.responseMessage = response.data.message;
          this.$refs.responseModal.open();
        },
        error: (error) => {
          this.responseMessage = error.response.message;
          console.log("err", error);
        },
      });

      // this.users.loading = false;
    },
    async affiliates(searchValue) {
      this.loading = true;
      let url = `${this.$baseurl}/admin/affiliates?limit=10&search=${searchValue}&filter=no-originator&order=asc`;
      if (!searchValue) {
        url = `${this.$baseurl}/admin/affiliates?limit=20&filter=no-originator&order=asc`;
      }

      await this.$get({
        url: url,
        headers: this.headers,
        success: (response) => {
          this.affiliateUsersModal = response.data.datatable.data;
        },
        error: (error) => {
          this.responseMessage = error.response.message;
          console.log("err", error);
        },
      });

      this.loading = false;
    },
    async searchEnterKey(enterEvent) {
      this.affiliates(enterEvent.target.value);
    },
    async submitSelectedAffiliate() {
      if (!this.selectedAffiliates?.length) {
        this.attachAffiliateError =
          "please select at least one affiliate before submitting";
        return false;
      }
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/admin/affiliates/map-affiliates-to-originator`,
        data: {
          originator: this.selectedAffiliate?.email,
          affiliates: this.selectedAffiliates,
        },
        headers: this.headers,
        success: (response) => {
          this.$refs.attachAffiliateModal.close();
          this.responseStatus = true;
          this.responseMessage = response.data.message;
          this.$refs.responseModal.open();
        },
        error: () => {
          this.attachAffiliateError = "server error";
          // console.log("err", error.response);
        },
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.copy-clipboard {
  padding: 0.5rem;
  bottom: 119%;
  width: 80px;
  color: black;
}
.copy-clipboard::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  --bg-opacity: 1;
}
</style>
